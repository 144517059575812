<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog.vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { ANT_DELIMITER } from '@/services/uuid-helper';
import DhmeManualImportCreateObjectDialog from '@/modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportCreateObjectDialog.vue';

export default defineComponent({
  name: 'DHMEManualImportObjects',
  components: {
    DhmeManualImportCreateObjectDialog,
    DeleteDialog,
    DynamicDataTable,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      manualImportObjectHeaders: [
        {
          text: 'Code',
          value: 'object_type',
          hasSlot: true,
          input: true,
          type: 'text',
        },
        {
          text: 'Description',
          value: 'name',
          hasSlot: true,
          input: true,
          type: 'text',
        },
        {
          text: 'Length',
          value: 'length',
          hasSlot: true,
          input: true,
          type: 'text',
          suffix: 'mm',
        },
        {
          text: 'Width',
          value: 'width',
          hasSlot: true,
          input: true,
          type: 'text',
          suffix: 'mm',
        },
        {
          text: 'Height',
          value: 'height',
          hasSlot: true,
          input: true,
          type: 'text',
          suffix: 'mm',
        },
        {
          text: 'Steel Quality',
          value: 'steel_quality',
          hasSlot: true,
          input: true,
          type: 'text',
        },
        {
          text: 'Category',
          value: 'category',
          type: 'text',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      isLoading: false,
      selectedCell: null,
      cellValue: null,
      objectToDelete: null,
      createObjectDialog: false,
      createObjectItem: {},
      deleteMode: false,
      categoryFilter: null,
      currentCellValue: null,
      ctrlAltDown: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'manualImportObjectsRegister',
      'manualImportObjectsRegisterTable',
      'manualImportElements',
      'manualImportObjectsFilter',
    ]),

    manualImportObjectsRegisterRecords() {
      let records = [...this.manualImportObjectsRegister];

      if (this.categoryFilter) {
        records = records.filter(
          (item) => item.category === this.categoryFilter && item.object_type
        );
      }
      return records.sort((a, b) =>
        a?.object_type.localeCompare(b?.object_type)
      );
    },
    elementCategories() {
      return this.manualImportElements
        .map((item) => item.element_category)
        .sort((a, b) => a.localeCompare(b));
    },
  },

  watch: {
    categoryFilter(value) {
      this.$store.commit('module_manual_import_update_objects_filter', value);
    },
  },
  mounted() {
    window.addEventListener('keydown', this.setEventListeners);
    window.addEventListener('keyup', this.setEventListeners);

    if (this.manualImportObjectsFilter) {
      this.categoryFilter = this.manualImportObjectsFilter;
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.setEventListeners);
    window.removeEventListener('keyup', this.setEventListeners);
  },
  methods: {
    ANT_DELIMITER() {
      return ANT_DELIMITER;
    },
    async setEventListeners(event) {
      if (
        event.ctrlKey &&
        event.altKey &&
        event.code === 'KeyD' &&
        event.type === 'keydown'
      ) {
        this.deleteMode = !this.deleteMode;
        this.selectedCell = null;
      }

      if (this.selectedCell && event.type === 'keydown') {
        if (event.altKey && event.ctrlKey) {
          this.ctrlAltDown = true;
          let split = this.selectedCell.split(ANT_DELIMITER);
          let columnIndex = this.manualImportObjectHeaders.findIndex(
            (column) => column.value === split[1]
          );
          let recordIndex = this.manualImportObjectsRegisterRecords.findIndex(
            (record) => record.id === split[0]
          );
          switch (event.key) {
            case 'ArrowLeft':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportObjectsRegisterRecords[recordIndex].id,
                this.manualImportObjectHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowRight':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportObjectsRegisterRecords[recordIndex].id,
                this.manualImportObjectHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowUp':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportObjectsRegisterRecords[recordIndex].id,
                this.manualImportObjectHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowDown':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportObjectsRegisterRecords[recordIndex].id,
                this.manualImportObjectHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;

            case 'Delete':
              await this.updateRecord(
                this.manualImportObjectsRegisterRecords[recordIndex].id,
                this.manualImportObjectHeaders[columnIndex].value,
                null
              );
              this.currentCellValue = null;
              return;
          }
          if (
            columnIndex >= 0 &&
            columnIndex <= this.manualImportObjectHeaders.length &&
            recordIndex >= 0 &&
            recordIndex < this.manualImportObjectsRegisterRecords.length
          ) {
            this.selectedCell = `${this.manualImportObjectsRegisterRecords[recordIndex].id}${ANT_DELIMITER}${this.manualImportObjectHeaders[columnIndex].value}`;
          }
        } else {
          this.ctrlAltDown = false;
        }
      } else {
        this.ctrlAltDown = false;
      }
    },
    async updateAndRowDown() {
      let split = this.selectedCell.split(ANT_DELIMITER);
      let columnIndex = this.manualImportObjectHeaders.findIndex(
        (column) => column.value === split[1]
      );
      let recordIndex = this.manualImportObjectsRegisterRecords.findIndex(
        (record) => record.id === split[0]
      );

      recordIndex = recordIndex + 1;

      if (
        columnIndex >= 0 &&
        columnIndex <= this.manualImportObjectHeaders.length &&
        recordIndex >= 0 &&
        recordIndex < this.manualImportObjectsRegisterRecords.length
      ) {
        this.selectedCell = `${this.manualImportObjectsRegisterRecords[recordIndex].id}${ANT_DELIMITER}${this.manualImportObjectHeaders[columnIndex].value}`;
        this.cellValue = this.getCellValue(
          this.manualImportObjectsRegisterRecords[recordIndex].id,
          this.manualImportObjectHeaders[columnIndex].value
        );
        this.currentCellValue = this.cellValue;
      }
    },
    setupDelete(object) {
      this.objectToDelete = object;
    },
    getCellValue(id, column) {
      let record = this.manualImportObjectsRegister.find(
        (item) => item.id === id
      );
      return record[column];
    },
    selectCell(id, header, value) {
      if (this.deleteMode) {
        this.updateRecord(id, header, null);
      } else {
        this.selectedCell = `${id}${ANT_DELIMITER}${header}`;
        this.cellValue = value;
        this.currentCellValue = value;
      }
    },
    resetCell(id, column) {
      let record = this.manualImportObjectsRegister.find(
        (item) => item.id === id
      );
      if (record) {
        record[column] = this.cellValue;
      }
      this.selectedCell = null;
      this.cellValue = null;
      this.currentCellValue = null;
    },

    async updateRecord(recordId, column, value) {
      this.isLoading = true;
      let body = {};
      body[column] = value;

      try {
        let record = this.manualImportObjectsRegister.find(
          (item) => item.id === recordId
        );
        record[column] = value;

        if (
          !['portal', 'headplate', 'roofbeam', 'windbracing'].includes(
            record.category
          ) &&
          value &&
          column === 'steel_quality'
        ) {
          this.$store.commit('showNotification', {
            content: 'Cannot add steel quality to a non steel category',
            color: 'warning',
          });
          this.isLoading = false;
          this.resetCell(recordId, column);
          return;
        }

        await this.$store.dispatch('manualImportUpdateObjectRecord', {
          recordId: recordId,
          recordBody: body,
        });
        this.isLoading = false;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: 'something went wrong',
          color: 'error',
        });
        this.resetCell(recordId, column);
      }
    },
    async deleteObject() {
      this.isLoading = true;
      await this.$store.dispatch(
        'manualImportDeleteObjectRecord',
        this.objectToDelete.id
      );
      this.objectToDelete = null;
      this.isLoading = false;
    },
  },
});
</script>

<template>
  <dynamic-data-table
    table-title="Object types"
    :table-records="manualImportObjectsRegisterRecords"
    :table-headers="manualImportObjectHeaders"
    auto-sort-column="object_type"
    class="flex-grow-1 ant-glass-background radius-0"
    :is-loading="isLoading"
    :items-per-page="-1"
    :table-id="manualImportObjectsRegisterTable"
    :project-id="project.id"
    export-file-name="manual_import_module_export"
    has-options
    can-delete
    @deleteItem="setupDelete"
  >
    <template #table-buttons>
      <v-autocomplete
        v-model="categoryFilter"
        dense
        single-line
        hide-details
        placeholder="Category"
        filled
        :items="elementCategories"
        clearable
        prepend-inner-icon="mdi-filter"
        class="normal-text-field mr-2"
      />

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="deleteMode ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="deleteMode = !deleteMode"
          >
            {{ deleteMode ? 'mdi-delete-empty' : 'mdi-delete-empty-outline' }}
          </v-icon>
        </template>
        <span>Toggle delete mode (Ctrl+Alt+D)</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="ctrlAltDown ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
          >
            mdi-arrow-all
          </v-icon>
        </template>
        <span
          >You can use Ctrl+Alt+Arrow keys to move from cell to cell when a cell
          is selected</span
        >
      </v-tooltip>
    </template>
    <template #table-actions>
      <v-chip v-if="deleteMode" color="warning" class="mr-2"
        ><v-icon>mdi-exclamation-thick</v-icon>DELETE MODE ACTIVE</v-chip
      >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ant-icon"
            v-bind="attrs"
            v-on="on"
            @click="createObjectDialog = true"
          >
            mdi-plus
          </v-icon>
        </template>
        <span>Create object type</span>
      </v-tooltip>
      <delete-dialog
        :title="`Are you sure you want to delete module ${objectToDelete?.module_id}`"
        :dialog="objectToDelete !== null"
        @closeDialog="objectToDelete = null"
        @deleteAction="deleteObject"
      />
      <dhme-manual-import-create-object-dialog
        :displayed="createObjectDialog"
        :category="categoryFilter"
        @closeDialog="createObjectDialog = false"
      />
    </template>
    <template
      v-for="header in manualImportObjectHeaders.filter((x) => x.input)"
      #[`item.${header.value}`]="{ value, rowId, item }"
    >
      <td :key="header.value" class="ant-border-left">
        <v-text-field
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}${header.value}`"
          v-model="currentCellValue"
          dense
          single-line
          hide-details
          filled
          :type="header.type"
          autofocus
          clearable
          :suffix="header.suffix"
          @keydown.enter="updateAndRowDown"
          @change="updateRecord(item.id, header.value, $event)"
          @keydown.esc="resetCell(item.id, header.value)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, header.value, value)"
        >
          {{ value }}
        </div>
      </td>
    </template>
    <template #item.category="{ value, rowId, item }">
      <td class="ant-border-left">
        <v-select
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}category`"
          :items="elementCategories"
          :value="value"
          class="normal-text-field"
          dense
          single-line
          hide-details
          filled
          clearable
          @change="updateRecord(rowId, 'category', $event)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, 'category', value)"
        >
          {{ value }}
        </div>
      </td>
    </template>
  </dynamic-data-table>
</template>

<style scoped lang="scss">
td {
  padding: 0 !important;
}
::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}
</style>
